import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// auth
const Login = lazy(() => import('../pages/auth/Login'));
const Logout = lazy(() => import('../pages/auth/Logout'));
// Add other auth components...

//Products
const ProductListing = lazy(() => import('../pages/products'));
const CreateProduct = lazy(() => import('../pages/create-product'));

//Users
const UserAccounts = lazy(() => import('../pages/user-account'));

interface RoutesProps {
  path: RouteProps['path'];
  name?: string;
  element?: RouteProps['element'];
  route?: React.ComponentType<RouteProps>;
  exact?: boolean | undefined;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// Define routes
const dashboardRoutes: RoutesProps = {
  path: '/dashboard',
  name: 'Dashboards',
  icon: 'airplay',
  header: 'Navigation',
  children: [
    {
      path: '/',
      name: 'Root',
      element: <Root />,
      route: PrivateRoute,
      roles: ['Admin', 'Viewer'],
    },
    {
      path: '*',
      name: 'Root',
      element: <Root />,
      route: PrivateRoute,
      roles: ['Admin', 'Viewer'],
    },
  ],
};

const productsRoutes: RoutesProps = {
  path: '/products',
  name: 'Products',
  route: PrivateRoute,
  roles: ['Admin', 'Viewer'],
  element: (
    <PrivateRoute component={ProductListing} roles={['Admin', 'Viewer']} />
  ),
};

const createProductRoutes: RoutesProps = {
  path: '/create-product',
  name: 'CreateProduct',
  route: PrivateRoute,
  roles: ['Admin'],
  element: <PrivateRoute component={CreateProduct} roles={['Admin']} />,
};

const updateProductRoutes: RoutesProps = {
  path: '/update-product/:id',
  name: 'UpdateProduct',
  route: PrivateRoute,
  roles: ['Admin'],
  element: <PrivateRoute component={CreateProduct} roles={['Admin']} />,
};

const userAccountsRoutes: RoutesProps = {
  path: '/user-accounts',
  name: 'UserAccounts',
  route: PrivateRoute,
  roles: ['Admin'],
  element: <PrivateRoute component={UserAccounts} roles={['Admin']} />,
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: '/auth/login',
    name: 'Login',
    element: <Login />,
  },
  {
    path: '/auth/logout',
    name: 'Logout',
    element: <Logout />,
  },
  // Add other auth routes...
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];
  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  dashboardRoutes,
  createProductRoutes,
  userAccountsRoutes,
  updateProductRoutes,
  productsRoutes,
];
const publicRoutes = [...authRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
