// import React from 'react';
// import { Route, Navigate, RouteProps } from 'react-router-dom';

// /**
//  * Private Route forces the authorization before the route can be accessed
//  * @param {*} param0
//  * @returns
//  */
// const PrivateRoute = ({ component: Component, ...rest }: any) => {
//   const token = localStorage.getItem('token');
//   const user = JSON.parse(localStorage.getItem('data') || '');

//   return (
//     <Route
//       {...rest}
//       render={(props: RouteProps) => {
//         if (!token) {
//           // not logged in, redirect to login page
//           return (
//             <Navigate
//               to={{
//                 pathname: '/auth/login',
//                 // state: { from: props.location },
//               }}
//             />
//           );
//         }
//         // user is logged in, check if route is restricted by role
//         if (user.role === 'Admin') {
//           // role is authorized, allow access to the component
//           return <Component {...props} />;
//         }

//         // user is not authorized, redirect to home page
//         return <Navigate to={{ pathname: '/' }} />;
//       }}
//     />
//   );
// };

// export default PrivateRoute;

import React from 'react';
import { Route, Navigate, RouteProps } from 'react-router-dom';

import { APICore } from '../helpers/api/apiCore';

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {
  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('data') || '');
  return (
    <>
      {!token ? (
        <Navigate
          to={{
            pathname: '/auth/login',
          }}
        />
      ) : roles.includes(user.role) ? (
        <Component />
      ) : (
        <Navigate to={{ pathname: '/' }} />
      )}
    </>
  );
};

export default PrivateRoute;
