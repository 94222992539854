import React from 'react';
import { Navigate } from 'react-router-dom';

const Root = () => {
  const isAuthToken = localStorage.getItem('token');

  const getRootUrl = () => {
    let url: string = 'landing';

    // check if user logged in or not and return url accordingly
    if (isAuthToken) {
      url = 'products';
    } else {
      url = 'api/auth/login';
    }
    return url;
  };

  const url = getRootUrl();

  return <Navigate to={`/${url}`} />;
};

export default Root;
